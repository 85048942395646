import React, { useState } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { CSSTransition } from "react-transition-group";
import MaximoForm from "../components/MaximoForm";
import MaximoServiceFacilities from "../components/MaximoServiceFacilities";
import MaximoCertification from "../components/MaximoCertification";
import MaximoSolutionList from "../components/MaximoSolutionList";
import Logo from "../components/Logo";
import Testimonials from "../components/Testimonials";
import MaximoScale from "../components/MaximoScale";
import "../styles/main.scss";
import MaximoLeadForm from "../components/MaximoLeadForm";

const MaximoCampaign = ({ data }) => {
    const [showModal, setShowModal] = useState(false);

    const handleModel = () => {
        setShowModal(true);
    };
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="text-p3  text-clr-secondary text-fw-regular gap-1x">{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <li className="text-p3  text-clr-secondary text-fw-regular">{children}</li>
            ),
        },
    };
    const maximoAI = [
        {
            id: 1,
            sequence: "01.",
            title: "Maximo Manage",
            description:
                "Scale your asset management and reliability journey with better planning and control over assets. Maximo Manage helps track and manage your entire asset lifecycle.",
        },
        {
            id: 2,
            sequence: "02.",
            title: "Maximo Visual Inspection",
            description:
                "Harness the power of no-code computer vision and AI to enforce 24/7 monitoring, increase worker safety and accelerate defect detection with easy and scalable AI models.",
        },
        {
            id: 3,
            sequence: "03.",
            title: "Maximo Predict",
            description:
                "Switch to proactive failure detection and mitigation through data-driven insights, health scores from predictive models and more.",
        },
        {
            id: 4,
            sequence: "04.",
            title: "Maximo Monitor",
            description:
                "Gain an enterprise-wide view of operations that helps maintenance & operational teams gain end-to-end visibility with configurable dashboards, alerts, drill-down and more.",
        },
        {
            id: 5,
            sequence: "05.",
            title: "Maximo Health",
            description:
                "Maximize your enterprise efficiency with Maximo Health. Gain insights into asset performance, optimize costs and reduce risks with custom health scores, visual KPI’s and more",
        },
        {
            id: 6,
            sequence: "06.",
            title: "Maximo Safety",
            description:
                "Maximo Safety enhances your enterprise’s safety and compliance. It provides integrated processes for improving safety, reliability, reducing operational risk, and increasing return on assets.",
        },
        {
            id: 7,
            sequence: "07.",
            title: "Maximo Assist",
            description:
                "Swiftly diagnose and repair equipment issues with AI-powered guidance, predictive insights, and seamless integration. Optimize maintenance and enhance asset health with Maximo Assist.",
        },
    ];
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {/* maximo campaign page hero-section  */}
            <section className="maximo-campaign-hero" id="maximo-campaign-hero">
                <div className="maximo-campaign-hero container">
                    <div className="logo-header">
                        <div className="site-logo">
                            <div to="/" className="logo-inner">
                                <div className="ring">
                                    <Logo />
                                </div>
                                <svg
                                    className="logo"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100"
                                    height="32.338"
                                    viewBox="0 0 100 32.338"
                                >
                                    <path
                                        d="M16.473-85.474c5.047,0,10.92-2.756,10.92-9.5,0-5.937-4.22-7.337-9.542-9.415-3.542-1.378-5.217-2.184-5.2-4.114.021-1.951,1.59-3.1,3.711-3.1,1.93,0,4.156.933,6.722,3.033l3.52-4.6a16,16,0,0,0-10.391-3.881c-5.535.064-9.9,3.86-9.9,8.822,0,5.81,4.665,7.973,9.331,9.606,3.732,1.294,5.365,2.1,5.365,4.368,0,1.908-1.484,3.265-4.432,3.265A10.555,10.555,0,0,1,8.9-94.846L5.234-90.329a15.553,15.553,0,0,0,11.238,4.856ZM49.3-96.628c0-6.213-4.177-10.772-10.412-10.772-6.022,0-10.518,4.5-10.518,10.942a10.515,10.515,0,0,0,10.92,10.984,11.288,11.288,0,0,0,9.161-4.008l-3.69-3.435a6.282,6.282,0,0,1-5.068,2.4,5.341,5.341,0,0,1-5.407-4.071H49.154a10.808,10.808,0,0,0,.148-2.036ZM39.082-102.4a4.5,4.5,0,0,1,4.581,3.711H34.311A4.671,4.671,0,0,1,39.082-102.4Zm27.55-15.415v13.168h-.106a8.216,8.216,0,0,0-6.4-2.757c-5.683,0-9.988,4.58-9.988,11.027,0,6.552,4.283,10.9,10.157,10.9A7.852,7.852,0,0,0,66.781-88.4h.127v2.566h5.407v-31.977Zm-5.3,27.248c-3.181,0-5.429-2.354-5.429-5.789,0-3.626,2.4-5.937,5.45-5.937a5.6,5.6,0,0,1,5.577,5.937,5.511,5.511,0,0,1-5.6,5.789Zm17.307-19.466a3.5,3.5,0,0,0,3.52-3.478,3.512,3.512,0,0,0-3.52-3.456,3.5,3.5,0,0,0-3.52,3.456A3.489,3.489,0,0,0,78.639-110.029ZM75.8-85.835H81.48V-107.04H75.8ZM97.537-107.4a8.838,8.838,0,0,0-6.807,3.1h-.064v-2.735H84.983v21.205h5.683V-96.5c0-3.775,1.93-5.789,4.814-5.789,2.63,0,4.071,1.251,4.071,5.3v11.175h5.683v-12.6c0-6.531-3.16-8.991-7.7-8.991Z"
                                        transform="translate(-5.234 117.812)"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="partner-logo">
                            <GatsbyImage
                                image={
                                    data.contentfulMaximoCampaignLandingpage.logo.gatsbyImageData
                                }
                                alt={data.contentfulMaximoCampaignLandingpage.logo.title}
                            />
                        </div>
                    </div>
                    <div className="maximo-campaign-hero__wrapper">
                        <div className="maximo-campaign-hero__grid">
                            <div>
                                <div className="maximo-campaign-hero__content">
                                    <div className="maximo-campaign-hero__text">
                                        <h1 className="text-h2 gap-1x text-fw-medium text-clr-primary">
                                            {/* Maximo consulting services */}
                                            {data.contentfulMaximoCampaignLandingpage.title}
                                        </h1>

                                        {renderRichText(
                                            data.contentfulMaximoCampaignLandingpage.description,
                                            options
                                        )}
                                    </div>
                                </div>
                            </div>

                            <MaximoForm
                                formTitle={data?.contentfulMaximoCampaignLandingpage?.formHeading}
                            />
                        </div>
                        <div className="maximo-campaign-clients__grid">
                            <p className="text-p3 text-clr-secondary text-fw-medium">Trusted by</p>
                            <div className="maximo-campaign-clients__grid-logo">
                                {data.contentfulMaximoCampaignLandingpage.clients.map((el, i) => (
                                    <GatsbyImage
                                        key={i}
                                        image={el.gatsbyImageData}
                                        alt={el.title}
                                        loading="lazy"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* use mas 8 AI section  */}
            {/* <section className="maximo-campaign-AI">
                <div className="container container--first">
                    <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                        Use MAS 8 on cloud to optimize your asset performance with AI
                    </h1>
                    <div className="maximo-campaign-AI-grid">
                        {maximoAI?.map((AI, i) => (
                            <div className="maximo-campaign-AI-grid_item" key={i}>
                                <span className="text-h3 span">{AI.sequence}</span>
                                <h3 className="text-h3 text-clr-primary text-fw-medium gap-05x">
                                    {AI.title}
                                </h3>
                                <p className="text-p3 text-clr-primary">{AI.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
            {/* scale with our maximo CoE section  */}
            <section className="maximo-campaign-solution">
                <div className="container container--mid-v2">
                    <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                        {/* Scale with our Maximo CoE for your asset management */}
                        {data.contentfulMaximoCampaignLandingpage.maximoServiceSolutionTitle}
                    </h1>
                    <MaximoScale
                        data={data.contentfulMaximoCampaignLandingpage.maximoServiceSolutionList}
                    />
                    <a
                        className="sdn-cta-btn"
                        // href="#maximo-campaign-hero"
                        onClick={handleModel}
                    >
                        {/* {selectedItem?.buttonText} */}
                        Contact Maximo experts
                    </a>
                </div>
            </section>
            {/* statistics section  */}
            <section className="maximo-statistics">
                <div className="container container--mid-v2">
                    <div className="maximo-statistics__grid">
                        <div className="maximo-statistics__grid-text">
                            <h2 className="text-h2 text-fw-medium text-clr-primary gap-2x">
                                Partner with experts
                            </h2>
                        </div>
                        <div className="maximo-statistics__grid-content">
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    15+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Years in Experience
                                </div>
                            </div>
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    50+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Projects Delivered
                                </div>
                            </div>
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    50+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Certified Maximo Consultants
                                </div>
                            </div>
                            <div className="maximo-statistics__grid-content-item">
                                <div className="maximo-statistics__grid-content-item__value">
                                    7+
                                </div>
                                <div className="maximo-statistics__grid-content-item__name">
                                    Years of IBM Partnership
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* testimonial section */}
            <section className="maximo-testimonials">
                <div className="container container--mid-v2">
                    <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                        Client Testimonials
                    </h1>
                    <Testimonials
                        testimonialData={data.contentfulMaximoCampaignLandingpage.maximoTestimonial}
                    />
                </div>
            </section>
            {/* Maximo IBM mobile implementation section */}
            {/* <section className="maximo-campaign-mobile">
                <div className="container container--mid-v2">
                    <div className="maximo-campaign-mobile__text-with-image">
                        <div className="maximo-campagin-mobile__text-with-image__onlytext">
                            <h1 className="text-h1 text-clr-primary text-fw-medium gap-1x">
                                {data.contentfulMaximoCampaignLandingpage.maximoIntroHeader}
                            </h1>
                            <div className="text-p3 text-fw-medium text-clr-secondary gap-1x">
                                <p>{data.contentfulMaximoCampaignLandingpage.maximoIntroTitle}</p>
                                {renderRichText(
                                    data.contentfulMaximoCampaignLandingpage.maximoIntroDescription,
                                    options
                                )}
                            </div>
                            <a
                                className="sdn-cta-btn"
                                // href="#maximo-campaign-hero"
                                onClick={handleModel}
                            >
                                Book a Demo
                            </a>
                        </div>
                        <div className="maximo-campaign-mobile__text-with-image__image">
                            <GatsbyImage
                                image={
                                    data.contentfulMaximoCampaignLandingpage.maximoIntroImage
                                        .gatsbyImageData
                                }
                                alt={
                                    data.contentfulMaximoCampaignLandingpage.maximoIntroImage.title
                                }
                            />
                        </div>
                    </div>
                </div>
            </section> */}
            {/* maximo on cloud section  */}
            {/* <section className="maximo-category">
                <div className="container container--mid-v2">
                    <MaximoServiceFacilities
                        maximoFacilities={
                            data.contentfulMaximoCampaignLandingpage.maximoServiceFacilities
                        }
                    />
                </div>
            </section> */}
            {/* certified consultants section  */}
            <section className="maximo-certified">
                <div className="container container--mid-v2">
                    <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                        {data.contentfulMaximoCampaignLandingpage.maximoCertifiedTitle}
                    </h1>
                    <MaximoCertification
                        data={data.contentfulMaximoCampaignLandingpage.maximoCertification}
                    />
                </div>
            </section>

            {/* maximo solution section  */}
            {/* <section className="maximo-solution">
                <div className="container container--mid-v2">
                    <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                        {data.contentfulMaximoCampaignLandingpage.maximoSolutionTitle}
                    </h1>
                    <MaximoSolutionList
                        solutionList={
                            data.contentfulMaximoCampaignLandingpage.maximoSolutionFacilities
                        }
                    />
                </div>
            </section> */}
            {/* ctabanner section  */}
            <section className="maximo-cta-banner">
                <div className="container container--mid-v2">
                    <div className="cta-banner">
                        <div className="cta-banner__grid-item">
                            <div className="cta-banner__grid-item__text">
                                <div className="text-h1 cta-text text-fw-medium gap-1x">
                                    {
                                        data.contentfulMaximoCampaignLandingpage.footerMaximoCta
                                            .header
                                    }
                                </div>
                                <ul className="text-p1">
                                    <li>In-depth audit of your existing Maximo environment</li>
                                    <li>Check your current implementation’s health score </li>
                                    <li>Custom migration roadmap for your enterprise</li>
                                </ul>
                                <a className=" sdn-cta-btn" onClick={handleModel}>
                                    {
                                        data.contentfulMaximoCampaignLandingpage.footerMaximoCta
                                            .ctaText
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                <MaximoLeadForm showModal={showModal} setShowModal={setShowModal} footer />
            </CSSTransition>
            <section className="maximo-footer">
                <div className="container container--mid-v2">
                    <div className="footer__bottom-text">
                        © {new Date().getFullYear()} &nbsp; Sedin Technologies Inc
                    </div>
                </div>
            </section>
        </>
    );
};

export const query = graphql`
    query MaximoCampaign($id: String) {
        contentfulMaximoCampaignLandingpage(id: { eq: $id }) {
            title
            description {
                raw
            }
            logo {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            clients {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            formHeading {
                raw
            }
            maximoServiceSolutionTitle
            maximoServiceSolutionList {
                id
                title
                name
                description {
                    description
                }
                solutionLists
                slug
                ogImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                buttonText
            }
            maximoIntroHeader
            maximoIntroTitle
            maximoIntroDescription {
                raw
            }
            maximoIntroImage {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            maximoServiceFacilities {
                icon {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                title
                description
            }
            maximoCertifiedTitle
            maximoCertification {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                title
            }
            maximoTestimonial {
                id
                personName
                personDesignation
                profileImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                }
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
            }
            maximoSolutionTitle
            maximoSolutionFacilities {
                icon {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    file {
                        url
                    }
                    title
                }
                title
                description
            }
            footerMaximoCta {
                ctaText
                ctaSlug
                header
            }
        }
    }
`;
export default MaximoCampaign;
