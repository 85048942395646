import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const MaximoCertification = ({ data }) => {
    return (
        <div className="maximo-certified__grid">
            {data.map((ele, i) => (
                <GatsbyImage key={i} image={ele.gatsbyImageData} alt={ele.title} />
            ))}
        </div>
    );
};

export default MaximoCertification;
