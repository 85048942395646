import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const MaximoScale = ({ data = null }) => {
    const [selectedItem, setSelectedItem] = useState({});
    // const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
    const handleItemClick = (id, index) => {
        const solution = data?.find((item) => item?.id == id);
        if (solution) setSelectedItem(solution);
        // setSelectedButtonIndex(index);
    };
    useEffect(() => {
        // setSelectedItem(data[0]);
        if (data && data.length > 0) {
            setSelectedItem(data[0]);
        }
    }, [data]);
    // console.log(data);
    return (
        <>
            <div className="maximo-campaign-solution__content">
                {data?.map((item, index) => (
                    <button
                        onClick={() => handleItemClick(item?.id)}
                        key={index}
                        className={`maximo-campaign-solution__content-item__solution-info__title ${
                            // selectedButtonIndex === index ? "selected-button" : ""
                            selectedItem?.id == item?.id ? "selected-button" : ""
                        }`}
                    >
                        {item?.title}
                    </button>
                ))}
            </div>

            <div className="maximo-campaign-solution__content-item__solution-info__grid">
                <div className="maximo-campaign-solution__content-item__solution-info__content">
                    <div>
                        <p className="maximo-campaign-solution__content-item__solution-info__description">
                            {selectedItem?.description?.description}
                        </p>
                        {/* <a className="sdn-cta-btn" href="#maximo-campaign-hero">
                        {selectedItem?.buttonText}
                    </a> */}
                    </div>
                    <div className="maximo-campaign-solution__content-item__solution-features">
                        {selectedItem?.solutionLists?.map((f, index) => (
                            <div key={`features_${selectedItem?.index}_${index}`}>
                                <ul>
                                    <li>{f}</li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="maximo-campaign-solution__content-item__solution-info__image gap-2x">
                    {selectedItem.ogImage ? (
                        <GatsbyImage
                            class="maximo-solution-ogimage"
                            image={selectedItem.ogImage?.gatsbyImageData}
                            alt={selectedItem.title}
                            // style={{ height: "500px" }}
                        />
                    ) : (
                        <img
                            className="maximo-solution-ogimage"
                            src={selectedItem.ogImage?.file.url || ""}
                            alt={selectedItem.title}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default MaximoScale;
